/* eslint-disable import/prefer-default-export */
import getCartAndSendEvent, { CartFunction } from "~/Gtm/getCartAndSendEvent";
import { sendGtmEvent } from "../definitions";
import {
  getFacebookPixelId,
  getSessionItem,
  setSessionItem,
} from "../apiHelpers";

export const fbPaymentInfo: CartFunction = (itemList, totalCost) => {
  if (typeof fbq !== "function") return;
  fbq("trackSingle", getFacebookPixelId(), "AddPaymentInfo", {
    value: totalCost,
    currency: "USD",
    content_ids: [itemList],
    content_type: "product",
  });
};

export const sendAddPaymentInfoEvent: CartFunction = (
  itemList,
  totalCost,
  discountCodes
) => {
  sendGtmEvent({
    event: "add_payment_info",
    ecommerce: {
      items: [itemList],
      value: totalCost,
      coupon: [discountCodes],
    },
  });
};

export const addPaymentInfo = (
  paymentType: string,
  isQuickCheckout: boolean
) => {
  sendGtmEvent({
    payment_type: paymentType,
    quick_checkout: isQuickCheckout,
  });

  if (!getSessionItem("payment_added")) {
    getCartAndSendEvent(fbPaymentInfo, sendAddPaymentInfoEvent);
    setSessionItem("payment_added", "1");
  } else {
    getCartAndSendEvent(sendAddPaymentInfoEvent);
  }
};
