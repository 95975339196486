export default {};

const error = new Error("The @sentry-internal/replay feature has been disable");

const getReplay = () => {
  throw error;
};

const replayIntegration = () => {
  throw error;
};

export { getReplay, replayIntegration };
