import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  forwardRef,
} from "react";
import { Button, ButtonProps } from "@clarkinc/zest-design-system/ui";
import { sendGtmEvent } from "~/analytics/definitions";
import { TrackedCarouselItem } from "./TrackedCarouselItem";

interface TrackingProps {
  "data-track": string;
  "data-track-ei"?: string | null;
  enableTracking?: boolean;
}

const sendSelectGTMEvent = (
  event: React.ChangeEvent<HTMLSelectElement>,
  callback?: React.ChangeEventHandler<HTMLElement>
) => {
  callback?.(event);
  sendGtmEvent({
    event: "feature_tracking",
    "data-track": event.target.selectedOptions[0]?.getAttribute("data-track"),
    "data-track-ei":
      event.target.selectedOptions[0]?.getAttribute("data-track-ei") ?? "",
    eventAction: "interaction",
  });
};

const sendOnClickGTMEvent = (
  event: React.MouseEvent<HTMLElement>,
  callback?: React.MouseEventHandler<HTMLElement>,
  enableTracking?: boolean
) => {
  callback?.(event);

  if (enableTracking !== false) {
    sendGtmEvent({
      event: "feature_tracking",
      "data-track": event.currentTarget.getAttribute("data-track"),
      "data-track-ei": event.currentTarget.getAttribute("data-track-ei") ?? "",
      eventAction: "interaction",
    });
  }
};

const sendOnFocusGTMEvent = (
  event: React.FocusEvent<HTMLInputElement>,
  callback?: React.FocusEventHandler<HTMLElement>
) => {
  callback?.(event);
  sendGtmEvent({
    event: "feature_tracking",
    "data-track": event.currentTarget.getAttribute("data-track"),
    "data-track-ei": event.currentTarget.getAttribute("data-track-ei") ?? "",
    eventAction: "interaction",
  });
};

const tracking = {
  /**
   * Select element used with trackable option elements
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  select: forwardRef(
    (
      props: SelectHTMLAttributes<HTMLSelectElement>,
      ref: ForwardedRef<HTMLSelectElement>
    ) => {
      // Note: Options can not trigger onClick events, so instead we rely on the onChange to trigger the event to GTM
      const { children, onChange, ...rest } = props;

      return (
        <select
          onChange={(event) => sendSelectGTMEvent(event, onChange)}
          ref={ref}
          {...rest}
        >
          {children}
        </select>
      );
    }
  ),
  /**
   * Trackable option element
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  option: (
    props: React.OptionHTMLAttributes<HTMLOptionElement> & TrackingProps
  ) => {
    const { children, ...rest } = props;
    return <option {...rest}>{children}</option>;
  },
  /**
   * Trackable anchor element
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  a: forwardRef(
    (
      props: React.AnchorHTMLAttributes<HTMLAnchorElement> & TrackingProps,
      ref: ForwardedRef<HTMLAnchorElement>
    ) => {
      const { onClick, children, href, enableTracking = true, ...rest } = props;
      return (
        <a
          onClick={(event) =>
            sendOnClickGTMEvent(event, onClick, enableTracking)
          }
          href={href}
          ref={ref}
          {...rest}
        >
          {children}
        </a>
      );
    }
  ),
  /**
   * Trackable button element
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  button: forwardRef(
    (
      props: ButtonHTMLAttributes<HTMLButtonElement> & TrackingProps,
      ref: ForwardedRef<HTMLButtonElement>
    ) => {
      const { children, onClick, ...rest } = props;

      return (
        <button
          ref={ref}
          type="button"
          {...rest}
          onClick={(event) => sendOnClickGTMEvent(event, onClick)}
        >
          {children}
        </button>
      );
    }
  ),
  /**
   * Trackable Zest Button element
   * Note this may be deprecated in the future and integrated directly into Zest.
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  Button: forwardRef(
    (
      props: ButtonProps & TrackingProps,
      ref: ForwardedRef<HTMLButtonElement>
    ) => {
      const { children, onClick, ...rest } = props;

      return (
        <Button
          ref={ref}
          type="button"
          {...rest}
          onClick={(event) => sendOnClickGTMEvent(event, onClick)}
        >
          {children}
        </Button>
      );
    }
  ),
  /**
   * Trackable input element
   * @see {@link [Tracking Documentation](https://backstage.clarkinc.biz/docs/default/component/storefront-website/tracking-component/)}
   */
  input: forwardRef(
    (
      props: InputHTMLAttributes<HTMLInputElement> & TrackingProps,
      ref: ForwardedRef<HTMLInputElement>
    ) => {
      const { onFocus, ...rest } = props;

      return (
        <input
          onFocus={(event) => sendOnFocusGTMEvent(event, onFocus)}
          ref={ref}
          {...rest}
        />
      );
    }
  ),
  carouselListItem: TrackedCarouselItem,
};

export default tracking;
