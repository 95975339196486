import React, { useEffect, useRef, useState } from "react";
import MenuDropdown from "../MenuDropdown";
import MenuItem from "../MenuItem";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AccountIcon from "../Icons/AccountIcon";
import OrderIcon from "../Icons/OrderIcon";
import ChevronDownIcon from "../Icons/ChevronDownIcon";
import sharedButtonStyles from "../sharedButtonStyles";
import Icons from "~/common/Icons/Standard";
import useIsMounted from "../../../useIsMounted";
import useMenuHandlers from "~/common/Header/hooks/useMenuHandlers";

interface NotLoggedInProps {
  checkout: boolean;
}

const NotLoggedIn = ({ checkout }: NotLoggedInProps) => {
  const isMounted = useIsMounted();
  const [accountButtonActive, setAccountButtonActive] = useState(false);
  const accountMenuRef = useRef<HTMLDivElement>(null);
  const accountButtonRef = useRef<HTMLButtonElement>(null);
  const accountMenuLiRef = useRef<HTMLLIElement>(null);

  const [targetUrl, setTargetUrl] = useState("/myaccount/");

  useEffect(() => {
    if (isMounted) {
      setTargetUrl(
        `/myaccount${
          window?.location?.pathname
            ? `/?target_url=${window.location.pathname}`
            : ""
        }`
      );
    }
  }, [isMounted]);

  const { closeMenus, handleEscape, toggleMenus, handleBlur } = useMenuHandlers(
    setAccountButtonActive,
    accountMenuLiRef,
    accountButtonRef
  );

  useOnClickOutside(accountMenuRef, accountButtonRef, closeMenus);

  return (
    <ul className="hidden items-center m-0 lt:flex lt:ml-2 lg:ml-8 xl:ml-[104px]">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <li
        className={`list-none relative ${checkout ? "pr-4" : ""}`}
        onBlur={handleBlur}
        ref={accountMenuLiRef}
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            handleEscape();
          }
        }}
      >
        <button
          type="button"
          ref={accountButtonRef}
          onClick={toggleMenus}
          className={`${sharedButtonStyles} ${
            accountButtonActive ? "bg-gray-200" : "bg-transparent"
          }`}
          data-testid="account-dropdown"
          aria-expanded={accountButtonActive}
        >
          <AccountIcon
            classNames="pr-1 self-center"
            style={{ minWidth: "12px", width: "12px", height: "16px" }}
          />
          <span className="leading-none">
            <span className="block text-xs leading-none font-normal text-left">
              Sign In
            </span>
            <span className="flex items-center text-sm leading-none">
              Account
              <ChevronDownIcon classNames="pl-1 text-gray-800 fill-current" />
            </span>
          </span>
        </button>
        <MenuDropdown active={accountButtonActive}>
          <div
            ref={accountMenuRef}
            className={`${
              accountButtonActive ? "block" : "hidden"
            } grid grid-cols-2`}
          >
            <div className="flex flex-col px-2-1/2 pt-2 pb-1">
              <div className="flex items-center pl-2 pr-4 py-1-1/2">
                <Icons name="rounded-home" className="w-5 h-4" />
                <p
                  className="ml-2 mb-0 text-sm font-bold leading-4"
                  id="my-account"
                >
                  My Account
                </p>
              </div>
              <ul aria-labelledby="my-account" className="list-none ml-0 mb-0">
                <MenuItem
                  name="global-header-sign-in-dropdown"
                  content="Sign In"
                  href={targetUrl}
                />
                <MenuItem
                  content="Create An Account"
                  href="/myaccount.html?goto=register"
                />
              </ul>
            </div>
            <div className="px-2-1/2 py-2 bg-gray-50 border-0 border-l border-solid border-gray-100 rounded-r">
              <div className="flex items-center pl-2 pr-4 py-1-1/2">
                <Icons name="bookmark" className="w-3 h-4" />
                <p
                  className="ml-2 mb-0 text-sm font-bold leading-4"
                  id="quick-links"
                >
                  Quick Links
                </p>
              </div>
              <ul className="list-none ml-0 mb-0" aria-labelledby="quick-links">
                <MenuItem
                  name="webstaurant-plus"
                  content="WebstaurantPlus"
                  href="/plus/"
                />
                <MenuItem
                  name="webstaurant-rewards"
                  content="Rewards"
                  href="/rewards/"
                />
                <MenuItem
                  name="webstaurant-app-quick-link"
                  content="Get the App"
                  href="/services/app/"
                />
                <MenuItem
                  name="versahub"
                  content="VersaHub"
                  href="/versahub#/"
                />
                <div className="border border-solid border-gray-200 border-b border-t-0 border-l-0 border-r-0 mt-4 mb-2" />
                <MenuItem
                  name="Track-your-order"
                  content="Track Your Order"
                  href="/trackorder.html"
                />
                <MenuItem
                  name="Help-center"
                  content="Help Center"
                  href="/ask.html"
                />
              </ul>
            </div>
          </div>
        </MenuDropdown>
      </li>
      {!checkout && (
        <li className="list-none pr-3.5 hidden lt:block ml-0 text-sm">
          <a
            href="/myaccount/orders/"
            className={`${sharedButtonStyles}`}
            data-testid="order-btn"
          >
            <OrderIcon
              classNames="pr-1"
              style={{ minWidth: "16px", width: "16px", height: "16px" }}
            />
            <span className="leading-none">
              <span className="block text-xs leading-none font-normal text-left">
                Returns &#38;
              </span>
              <span className="flex items-center text-sm leading-none">
                Orders
              </span>
            </span>
          </a>
        </li>
      )}
    </ul>
  );
};

export default NotLoggedIn;
