import { env } from "~/common/Environment";

/** You don't need this as the `imageHost` will never change.
 * The Jest tests were not working when trying to test the variable export so we tested the function instead.
 */
export const getImageHost = () => {
  if (env.isProd) return "https://cdnimg.webstaurantstore.com";
  if (env.isDev) return "https://www.dev.webstaurantstore.com";
  return "";
};

const imageHost = getImageHost();

// eslint-disable-next-line import/prefer-default-export
export { imageHost };
