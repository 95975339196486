import { sendGtmEvent } from "../definitions";
import {
  fbInitiateCheckout,
  getSessionItem,
  sendFacebookPixelViewContentEvent,
  setSessionItem,
} from "../apiHelpers";
import getCartAndSendEvent from "~/Gtm/getCartAndSendEvent";
import Store from "~/common/Store";
import { sendBingProductPageViewEvent } from "~/bingAnalytics";
import { AnalyticsProduct, createProducts } from "../productHelpers";

type CheckoutEvent = {
  event: string;
  ecommerce: {
    checkout: {
      actionField: {
        step: number;
        option: string;
      };
      products?: AnalyticsProduct[];
    };
  };
};

export const PageTypes = {
  Cart: 1,
  QuickCheckout: 2,
  BillingPage: 3,
  ReviewPayment: 4,
  Checkout: 4,
};

// eslint-disable-next-line import/prefer-default-export
export const sendCheckoutEventPageView = (
  pageType: keyof typeof PageTypes,
  funnelType: string,
  products?: AnalyticsProduct[]
) => {
  const event: CheckoutEvent = {
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: PageTypes[pageType],
          option: funnelType,
        },
      },
    },
  };
  if (products) {
    event.ecommerce.checkout.products = products;
  }
  sendGtmEvent(event);
};

export const getCheckoutFunnelType = () => {
  let isLoggedIn = false;
  let isLeasing = false;
  const { index } = Store.getState().user;
  if (index && Number(index) > 0) {
    isLoggedIn = true;
  }

  const commonCarrier = document.getElementById("common_carrier");
  // NOTE: This is obviously not correct and should be reviewed later
  const isLiftGate = commonCarrier !== null;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const leasing = document.getElementById("isLeasing") as HTMLInputElement;
  if (leasing !== null) {
    isLeasing = leasing.value === "true";
  }

  return (
    (isLoggedIn ? "Logged In" : "Guest") +
    (isLiftGate ? " With LiftGate" : "") +
    (isLeasing ? " With Leasing" : "")
  );
};

export const initiateLegacyFacebookCheckout = () => {
  if (!getSessionItem("checkout_initiated")) {
    getCartAndSendEvent(fbInitiateCheckout);
    setSessionItem("checkout_initiated", "1");
  }
};

export const productPageView = async (itemNumber: string) => {
  const products = await createProducts([{ itemNumber }]);
  if (products[0]) {
    sendGtmEvent({
      event: "productPageView",
      ecommerce: {
        currencyCode: "USD",
        detail: {
          products,
        },
      },
    });
    sendBingProductPageViewEvent(products[0].id);
    sendFacebookPixelViewContentEvent(products[0].id);
  }
};
