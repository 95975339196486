/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

import { Throwable } from "@wss/error-tracking/throwable";
import { toError } from "@wss/error-tracking/utils";
import { getDynamicData } from "~/common/Store/actions/updateUserAndChatFromOrigin";
import { getCarouselInteraction, Item } from "~/userAnalytics";
import {
  carouselIdDimension,
  itemNumberDimension,
  productReviewCountDimension,
  vendorDimension,
} from "./definitions";

type MarketingProductResult = {
  description: string;
  feedIdentifier: string;
  price: number;
  brand: string;
  category: string;
  itemNumber: string;
  vendorCode: string;
  productReviewCount: number;
  categoryId: number;
};

export interface AnalyticsProduct {
  name: string;
  id: string;
  price: string;
  brand: string;
  category: string;
  itemNumber?: string;
  quantity?: string;
  dimension39?: string;
  dimension40?: string;
  dimension52?: string;
  dimension64?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_name?: string;
  position?: number;
  categoryId: number;
  feedIdentifier?: string;
}

export const getProductData = async (
  itemNumbers: string
): Promise<Throwable<MarketingProductResult[]>> => {
  const result = await getDynamicData;
  if (result.isError) return result;
  const formattedItemNumbers = encodeURIComponent(itemNumbers);
  const getProductDataUrl = `/marketing:analytics/getproductdataforanalytics/?itemNumbers=${formattedItemNumbers}`;

  try {
    const response = await fetch(getProductDataUrl, { method: "GET" });
    if (!response.ok) {
      return {
        isError: true,
        error: new Error(
          "Failed to fetch product data from marketing analytics",
          { cause: response.statusText }
        ),
      };
    }
    const products: MarketingProductResult[] = await response.json();
    return { isError: false, value: products };
  } catch (e) {
    return { isError: true, error: toError(e) };
  }
};

export const createProducts = async (items: Item[]) => {
  const products: AnalyticsProduct[] = [];
  const itemNumbers = items.map((item: Item) => item.itemNumber);
  if (!itemNumbers || itemNumbers.length === 0) {
    return [];
  }

  const data = await getProductData(itemNumbers.toString());
  if (data.isError) {
    return [];
  }
  data.value.forEach((result: MarketingProductResult, index: number) => {
    const productLookup = items.find(
      (item) =>
        item.itemNumber.trim().toUpperCase() ===
        result.itemNumber.trim().toUpperCase()
    );

    const carouselLookup = getCarouselInteraction();
    const carouselId =
      carouselLookup[result.itemNumber.trim().toUpperCase()] ?? "";

    const categories = result.category.split("/");

    const product: AnalyticsProduct = {
      name: result.description,
      id: result.feedIdentifier,
      price: result.price.toString(),
      brand: result.brand,
      category: result.category,
      itemNumber: result.itemNumber,
      [vendorDimension]: result.vendorCode,
      [itemNumberDimension]: result.itemNumber.toUpperCase().trim(),
      [carouselIdDimension]: carouselId,
      [productReviewCountDimension]: result.productReviewCount.toString(),
      item_category: categories[0],
      item_category2: categories[1],
      item_category3: categories[2],
      item_category4: categories[3],
      item_category5: categories[4],
      position: index,
      categoryId: result.categoryId,
      feedIdentifier: result.feedIdentifier,
    };

    if (productLookup?.quantity) {
      product.quantity = productLookup.quantity.toString();
    } else {
      product.quantity = "0";
    }

    products.push(product);
  });

  return products;
};

export const createListingProduct = async (productElement: Element) => {
  const itemNumber = productElement.getAttribute("data-itemnumber");
  if (!itemNumber) return [];
  const products = await createProducts([{ itemNumber }]);

  if (products[0]) {
    const returnedProduct = products[0];
    const position = Number(productElement.getAttribute("data-position"));
    if (position && position >= 0) {
      returnedProduct.position = position;
    }
  }
  return products;
};
