/** Sentry logging for the browser */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */

/** This is an exception for the primary import */
// eslint-disable-next-line no-restricted-imports
import {
  BrowserOptions,
  init,
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/react";
import { env } from "~/common/Environment";
import Store from "~/common/Store";
import {
  getRecentSentryBreadcrumbs,
  isLoggingNetworkErrorDuringPageUnload,
  isProbablyBlockedByAdblockNetworkError,
  isProbablyCausedByHoneyExtension,
  isSimpleIgnoredEvent,
} from "./inbound-filters";

const sentryUrls = {
  local: null,
  development:
    "https://6fae599b54852828599dd44ffdc39435@o4505671666630656.ingest.us.sentry.io/4508518241140736",
  test:
    "https://24ae94f2668498360d6f72736047b8d5@o4505671666630656.ingest.us.sentry.io/4508518241140736",
  production:
    "https://6c80f7e0154d46a4d132fc1c60b0c81e@o4505671666630656.ingest.us.sentry.io/4508518241140736",
};
// Change the line below to `const url = sentryUrls.dev` to enable local logging.
const url = sentryUrls[env.current];

const hasConductrics = !!document.querySelector(
  'script[src$="/cdt/js/script.js"]'
);
const conductricsErrors = [
  "Minified React error #425", // Text content error
  "Minified React error #418", // Hydration error
  "Minified React error #423", // Hydration recover error
  "Text content does not match server-rendered HTML",
  "Hydration failed because the server rendered HTML didn't match the client",
  "There was an error while hydrating but React was able to recover by instead client rendering the entire root",
];
/** @see {@link https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-ignore-errors Sentry Docs} */
const ignoreErrors: NonNullable<InitSentryArgs["ignoreErrors"]> = [
  "Failed to fetch",
  "Loading chunk",
  "Loading CSS chunk",
  "NetworkError when attempting to fetch resource",
  "Load failed",
  "ResizeObserver",
  "Object Not Found Matching Id",
  "The operation was aborted.",
  "Form values does not contain", // Needed until 1220972 is completed
];
if (hasConductrics) {
  ignoreErrors.push(...conductricsErrors);
}

const remoteDomains = [
  "https://www.dev.webstaurantstore.com/",
  "https://argo.dev.webstaurantstore.com/",
  "https://www.test.webstaurantstore.com/",
  "https://www.webstaurantstore.com/",
  "https://argo.webstaurantstore.com/",
];

const localDomains = [
  "https://www.loc.webstaurantstore.com/",
  "https://localhost:5001",
];

const allDomains = [...remoteDomains, ...localDomains];

type InitSentryArgs = BrowserOptions & {
  initialScope: BrowserOptions["initialScope"] & {
    tags: {
      [key: string]: string | number | boolean;
    };
  };
};

export function initSentry(page: string, args?: InitSentryArgs): void {
  if (!url) {
    // eslint-disable-next-line no-console
    return console.info("Skipping Sentry init for local development");
  }

  if (args?.ignoreErrors) {
    ignoreErrors.concat(...args.ignoreErrors);
  }

  init({
    dsn: url,
    ...args,
    ignoreErrors,
    environment: env.current,
    allowUrls: allDomains,
    tracesSampleRate: env.isProd ? 0.5 : 0,
    profilesSampleRate: env.isProd ? 1 : 0,
    tracePropagationTargets: remoteDomains,
    integrations: [browserTracingIntegration(), browserProfilingIntegration()],
    initialScope: {
      ...args?.initialScope,
      user: { id: Store.getState().user.index },
      tags: { page, ...args?.initialScope?.tags },
    },
    beforeSend(event, hint) {
      if (isSimpleIgnoredEvent(event, hint)) {
        return null;
      }

      const breadcrumbs = getRecentSentryBreadcrumbs(event);
      if (!breadcrumbs) return event;
      if (isProbablyBlockedByAdblockNetworkError(breadcrumbs)) {
        if (!env.isProd) {
          console.warn("Silencing error due to add blocker", breadcrumbs);
        }
        return null;
      }
      if (isLoggingNetworkErrorDuringPageUnload(breadcrumbs)) {
        if (!env.isProd) {
          console.warn(
            "Silencing error due to page not loaded or unloaded",
            breadcrumbs
          );
        }
        return null;
      }
      if (isProbablyCausedByHoneyExtension(breadcrumbs)) {
        if (!env.isProd) {
          console.warn(
            "Silencing error caused by honey extension",
            breadcrumbs
          );
        }
        return null;
      }

      return event;
    },
  });
}
