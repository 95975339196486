import { IsEmptyGuid } from "../../common/helpers/constants";
import { OperationResponse } from "../Common/operationResponse";
import { ExistingShippingAddress } from "../EditSubordinate/EditShippingModal";
import { PlusSubscription, PlusSubscriptionList } from "./plusSubscription";

export type NewSubscriptionRequest = {
  shippingAddressId: number;
  paymentTokenId: number;
  promotionName: string | null;
};

export class PlusSubscriptionProvider {
  private refreshList: { (subs: PlusSubscriptionList): void }[] = [];

  async getSubscriptions(
    userGuid: string
  ): Promise<OperationResponse<PlusSubscriptionList>> {
    try {
      if (!IsEmptyGuid(userGuid)) {
        const response = await fetch(`/myaccount/spa/plus/user/${userGuid}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const responseData = await response.json<PlusSubscriptionList>();

          this.refreshList.forEach((func) => func(responseData));
          return { result: responseData };
        }
      }
    } catch (err) {
      import("@sentry/react").then(({ captureException }) => {
        captureException(err);
      });
    }

    return { error: "Could not retrieve subscription information" };
  }

  async getSubscription(
    userGuid: string,
    subscriptionId: number
  ): Promise<OperationResponse<PlusSubscription>> {
    const subscriptions = await this.getSubscriptions(userGuid);
    if (subscriptions.error) {
      return { error: subscriptions.error };
    }

    return {
      result: subscriptions.result?.subscriptions.find(
        (s) => s.id === subscriptionId
      ),
    };
  }

  async addSubscriptions(
    userGuid: string,
    subList: NewSubscriptionRequest[]
  ): Promise<OperationResponse<PlusSubscriptionList>> {
    const response = await fetch("/myaccount/spa/plus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ userGuid, newSubscriptions: subList }),
    });

    if (response.ok) {
      const updateList = await this.getSubscriptions(userGuid);
      if (updateList.result) {
        return { result: updateList.result };
      }
      return {
        error:
          "Subscriptions were added, but an error occurred retrieving the updated list",
      };
    }

    return {
      error: "One or more errors occurred adding new Plus subscriptions",
    };
  }

  async updateSubscriptionAddress(
    subscriptionId: number,
    userGuid: string,
    updatedAddress: ExistingShippingAddress
  ): Promise<OperationResponse<PlusSubscription>> {
    const response = await fetch(
      `/myaccount/spa/plus/${subscriptionId}/address`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(updatedAddress.id),
      }
    );

    if (response.ok) {
      return this.getSubscription(userGuid, subscriptionId);
    }

    return {
      error:
        "An error occurred updating the shipping address for the subscription",
    };
  }

  async updateSubscriptionPayment(
    subscriptionId: number,
    userGuid: string,
    selectedPaymentId: number
  ): Promise<OperationResponse<PlusSubscription>> {
    console.log(
      `Updating subscription ${subscriptionId} with payment ${selectedPaymentId}`
    );
    const response = await fetch(
      `/myaccount/spa/plus/${subscriptionId}/payment`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedPaymentId),
      }
    );

    if (response.ok) {
      return this.getSubscription(userGuid, subscriptionId);
    }

    return {
      error: "An error occurred updating the payment for the subscription",
    };
  }

  async registerForUpdates(func: (subs: PlusSubscriptionList) => void) {
    this.refreshList.push(func);
  }
}

const provider = new PlusSubscriptionProvider();

export const plusSubscriptionProvider = () => {
  return provider;
};
