import { useCallback } from "react";

const useMenuHandlers = (
  setMenuActive: React.Dispatch<React.SetStateAction<boolean>>,
  menuLiRef: React.RefObject<HTMLLIElement>,
  buttonRef: React.RefObject<HTMLButtonElement>
) => {
  const closeMenus = useCallback(() => {
    setMenuActive(false);
  }, [setMenuActive]);

  const handleEscape = useCallback(() => {
    if (
      menuLiRef.current?.contains(document.activeElement) ||
      (buttonRef.current && buttonRef.current === document.activeElement)
    ) {
      closeMenus();
      buttonRef.current?.focus();
    }
  }, [closeMenus, menuLiRef, buttonRef]);

  const toggleMenus = useCallback(() => {
    setMenuActive((prev) => !prev);

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [setMenuActive, buttonRef]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      // Fix for Safari not picking up the relatedTarget location
      setTimeout(() => {
        if (
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          !menuLiRef.current?.contains(e.relatedTarget as Node) &&
          menuLiRef.current?.contains(e.target)
        ) {
          closeMenus();
        }
      }, 300); // Ensure that the relatedTarget gets focus before function runs
    },
    [closeMenus, menuLiRef]
  );

  return { closeMenus, handleEscape, toggleMenus, handleBlur };
};
export default useMenuHandlers;
