// eslint-disable-next-line no-restricted-imports
import type {
  Breadcrumb,
  SeverityLevel,
  captureException,
} from "@sentry/react";
import { env } from "~/common/Environment";
import { getErrorMessage, toError } from "./utils";

function wrapError<A extends unknown[], R>(
  cb: (...args: A) => R,
  ...args: A
): R {
  if (!env.isProd) {
    // eslint-disable-next-line no-console
    console.error("Sentry Error", ...args.filter(Boolean));
  }
  return cb(...args);
}

/**
 * Imports the module used for remote error tracking.
 * Currently we use {@link https://sentry.io/welcome/ Sentry}.
 *
 * {@link https://docs.sentry.io/platforms/javascript/usage/ Sentry's Documentation}
 * @example
 *
 * import { getErrorTracking } from "@wss/error-tracking"
 *
 * try {
 *  // I failed :(
 * } catch(error) {
 *   // Logger documentation can be found in the link above.
 *   const logger = await getErrorTracking();
 * }
 */
// eslint-disable-next-line import/prefer-default-export
export async function getErrorTracking(): Promise<
  typeof import("@sentry/react")
> {
  const module = await import("@sentry/react");
  const entry = module.default || module;
  function wrappedCaptureException(
    ...args: Parameters<typeof entry.captureException>
  ): ReturnType<typeof entry.captureException> {
    return wrapError(entry.captureException, ...args);
  }
  function wrappedCaptureMessage(
    ...args: Parameters<typeof entry.captureMessage>
  ): ReturnType<typeof entry.captureMessage> {
    return wrapError(entry.captureMessage, ...args);
  }
  function wrappedAddBreadcrumb(
    ...args: Parameters<typeof entry.addBreadcrumb>
  ): ReturnType<typeof entry.addBreadcrumb> {
    return wrapError(entry.addBreadcrumb, ...args);
  }
  return {
    ...entry,
    captureException: wrappedCaptureException,
    captureMessage: wrappedCaptureMessage,
    addBreadcrumb: wrappedAddBreadcrumb,
  };
}

/**
 * Handles logging an error to the current error tracking platform.
 * @example
 * import { logException } from "@wss/error-tracking"
 *
 * try {
 *  // I failed :(
 * } catch(error) {
 *   logException(error);
 *   // or
 *   logException(error, "Info");
 * }
 */
export async function logException(
  error: unknown,
  level?: SeverityLevel,
  context?: Parameters<typeof captureException>[1]
): Promise<void> {
  const tracking = await getErrorTracking();
  const opts = level ? Object.assign(context ?? {}, { level }) : undefined;

  tracking.captureException(toError(error), opts);
}

/** Logs a message to the current error tracking platform.
 * @example
 * import { logMessage } from "@wss/error-tracking"
 *
 * function doSomething() {
 *  logMessage("Something we should know about", "Info");
 *  logMessage("Something we should look into", "Warning");
 *  logMessage("Something we NEED to look into", "Error");
 *  logMessage("Drop everything and fix this", "Fatal");
 * }
 */
export async function logMessage(
  message: string,
  level: SeverityLevel
): Promise<void> {
  const tracking = await getErrorTracking();
  tracking.captureMessage(message, level);
}

/**
 * @deprecated
 * This function does not capture the stack trace making it more difficult to find where an error came from.
 *
 * If you are logging a string use `logMessage` instead.
 *
 * If you are logging an error and need a different severity use `logException(error, "SEVERITY")`.
 *
 * Logs the message from an `Error`.
 *
 * @example
 * import { logErrorMessage } from "@wss/error-tracking"
 *
 * try {
 *  // I failed :(
 * } catch(error) {
 *   logErrorMessage(error, "Info");
 * }
 */
export async function logErrorMessage(
  error: unknown,
  severity: SeverityLevel
): Promise<void> {
  const message = getErrorMessage(error);
  return logMessage(message, severity);
}

/**
 * Attaches a new breadcrumb to the current issue context.
 *
 * Breadcrumbs do not result in sentry issues, however they will get attached to any reported
 * error events in the current session and may be used to expose additional debug information
 * related to the path the application took to reach an exception.
 *
 * {@link https://docs.sentry.io/platforms/javascript/enriching-events/breadcrumbs/ More info from Sentry}
 *
 * @example
 * import { logException, addBreadcrumb } from "@wss/error-tracking"
 *
 * if(condition){
 *  await addBreadCrumb({message: "Processing with 'condition'"});
 * }
 *
 * if(!requiredParam){
 *  await addBreadCrumb({message: "Missing required parameter requiredParam"}, "Error");
 * }
 *
 * await addBreadCrumb({
 *   message : `In buggy function with ${value}`,
 *   type: "debug"
 * });
 *
 * await addBreadCrumb({
 *   message : `In buggy function with unstructured debug data`,
 *   data : {
 *     type: "video"
 *     title: "Never gonna give you up",
 *     author: "R. Astley"
 *   }
 * });
 *
 * await logException(new Error("Breadcrumbs aren't sent until an issue is generated (now)"));
 */
export async function addBreadcrumb(
  breadcrumb: Breadcrumb,
  severity?: SeverityLevel
) {
  const tracking = await getErrorTracking();
  tracking.addBreadcrumb({ ...breadcrumb, level: severity });
}
