/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cls } from "cls";
import React, { useState } from "react";
import { NavItemData } from "./Item";

interface Props {
  navDataItems: NavItemData[];
  mouseEnterMenuItem(displayName: string): void;
  mouseLeaveMenuItem(displayName: string): void;
}

const ShowMoreCategories = ({
  navDataItems,
  mouseEnterMenuItem,
  mouseLeaveMenuItem,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleShowMoreCategoriesExpand = () => {
    if (isExpanded) {
      mouseLeaveMenuItem("showMore");
      setIsExpanded(false);
    } else {
      mouseEnterMenuItem("showMore");
      setIsExpanded(true);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, HTMLElement>) => {
    const notWithinMoreDropdown = !e.relatedTarget?.closest(
      "#showMoreCategories"
    );

    if (notWithinMoreDropdown) {
      mouseEnterMenuItem("");
      setIsExpanded(false);
    }
  };

  return (
    <div
      onMouseLeave={() => setIsExpanded(false)}
      onMouseEnter={handleShowMoreCategoriesExpand}
      onBlur={handleBlur}
      data-testid="show-more-categories"
      data-type="showMore"
      id="showMoreCategories"
    >
      <button
        className={cls(
          "relative",
          "!flex",
          "flex-auto",
          "justify-center",
          "items-center",
          "border-0",
          "text-white",
          "text-sm",
          "font-semibold",
          "mt-0.5",
          "-mr-1",
          "rounded-t",
          "antialiased",
          "pb-[3px]",
          "h-[38px]",
          isExpanded ? "lt:text-black lt:bg-gray-50 h-full" : "bg-transparent"
        )}
        tabIndex={0}
        onClick={handleShowMoreCategoriesExpand}
        type="button"
        style={{ textShadow: "none" }}
        aria-expanded={isExpanded}
      >
        More
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          xmlns="http://www.w3.org/2000/svg"
          className={`pl-1.5 ${isExpanded ? "fill-black" : "fill-white"}`}
        >
          <path
            d="M4 3.18205L7.11125 0L8 0.908974L4 5L0 0.908974L0.888749 0L4 3.18205Z"
            fill="current"
            stroke={isExpanded ? "black" : "white"}
            strokeWidth={0.25}
          />
        </svg>
      </button>
      {isExpanded && (
        <ul
          className="bg-gray-50 border-gray-200 rounded-b border-solid border-b border-l-0 border-r-0 border-t-0 list-none ml-0 mt-1 py-2 absolute right-0 z-50 top-9 mr-0.5"
          style={{
            minWidth: "170px",
            boxShadow:
              "0px 4px 4px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.06)",
          }}
        >
          {navDataItems.map((item) => (
            <li key={item.displayName}>
              <a
                className=" text-black hover:underline block pl-4 py-2"
                href={item.link}
              >
                {item.displayName}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ShowMoreCategories;
