/* eslint-disable @typescript-eslint/no-empty-function */
export default {};

const error = new Error(
  "The @sentry-internal/feedback feature has been disabled"
);

const sendFeedback = () => {
  throw error;
};
const getFeedback = () => {
  throw error;
};
const feedbackScreenshotIntegration = () => {
  throw error;
};
const buildFeedbackIntegration = () => {
  // Sentry calls this internally so we can't warn about it
};

export {
  sendFeedback,
  getFeedback,
  feedbackScreenshotIntegration,
  buildFeedbackIntegration,
};
