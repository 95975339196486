import { storageFactory } from "storage-factory";
import PinterestTrackingPixel from "./analytics/TrackingPixels/PinterestTrackingPixel";
import PinterestApiEvent from "./analytics/MarketingEvents/Pinterest/PinterestApiEvent";
import Store from "./common/Store";
import {
  trackYmanCarouselViews,
  bindYmanClickEvent,
  trackRPFYCarouselViews,
  getIsInternalUserStatus,
  getRandomNumberString,
  sendHashedCustomerEmailSignupEvent,
  CheckForUserDataChangesAndUpdate,
  bindTrackingForElements,
  getDataForProductsInRow,
  trackCarouselImpression,
  sendFacebookPixelLeadEvent,
} from "./userAnalytics";
import { sendGtmEvent } from "./analytics/definitions";
import { addPriceSpiderTokenToSessionStorage } from "~/FullCheckout/CheckoutComplete/OrderConfirmation/Analytics/PriceSpiderAnalytics";
import { getDynamicData } from "./common/Store/actions/updateUserAndChatFromOrigin";
import PinterestTagPageVisitEvent from "./analytics/TrackingPixels/TagEvents/PinterestTagPageVisitEvent";
import MarketingEventHelpers from "./analytics/MarketingHelpers/MarketingEventHelpers";
import { getCartAndSendShippingEvent } from "./Gtm/getCartAndSendEvent";
import {
  getFacebookPixelId,
  getFacebookPixelUserData,
  sendFacebookPixelServerEvent,
  getUserForPinterestEvent,
  setViewCartShipping,
} from "./analytics/apiHelpers";
import {
  sendCheckoutEventPageView,
  getCheckoutFunnelType,
  initiateLegacyFacebookCheckout,
} from "./analytics/PageView/pageViewHelper";
import { addPaymentInfo } from "./analytics/Payment/paymentHelpers";

const session = storageFactory(() => window.sessionStorage);
window.dataLayer = window.dataLayer || [];

/* global fbq, gacData, XMLHttpRequest */
export const emailSignupEvent = (email) => {
  sendGtmEvent({ event: "emailsignup" });
  sendHashedCustomerEmailSignupEvent(email);
  sendFacebookPixelLeadEvent();
};

export const sendFacebookPixelInternalUserEvent = async () => {
  const eventId = getRandomNumberString();
  const userData = getFacebookPixelUserData();

  if (typeof fbq === "function") {
    fbq("trackSingleCustom", getFacebookPixelId(), "InternalUser", {
      eventID: eventId,
      userData,
    });

    sendFacebookPixelServerEvent("InternalUser", eventId, {
      userData,
    });
  }
};

export const sendFacebookPixelPageViewEvent = async () => {
  const eventId = getRandomNumberString();

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "PageView", { eventID: eventId });
    sendFacebookPixelServerEvent(
      "PageView",
      eventId,
      getFacebookPixelUserData()
    );
  }
};

export const sendFacebookPixelPurchaseEvent = async (
  orderNumber,
  feedIdentifiers,
  orderValueSubtotal,
  isFirstTimePurchaser
) => {
  const orderValueSubtotalFloat = MarketingEventHelpers.createItemPriceWithTrailingZero(
    parseFloat(orderValueSubtotal)
  );

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "Purchase", {
      content_ids: feedIdentifiers,
      value: orderValueSubtotalFloat,
      currency: "USD",
      content_type: "product",
      is_first_time_purchase: isFirstTimePurchaser,
      eventID: orderNumber,
    });

    sendFacebookPixelServerEvent(
      "Purchase",
      orderNumber,
      getFacebookPixelUserData(),
      {
        content_ids: feedIdentifiers,
        value: orderValueSubtotalFloat,
        currency: "USD",
        content_type: "product",
        is_first_time_purchase: isFirstTimePurchaser,
      }
    );
  }
};

const waitForFacebookPixel = async () => {
  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  /* eslint-enable */
};

const loadFacebookPixel = async () => {
  await waitForFacebookPixel();

  fbq("dataProcessingOptions", ["LDU"], 0, 0);
  fbq("init", getFacebookPixelId(), getFacebookPixelUserData());

  sendFacebookPixelPageViewEvent();

  if (getIsInternalUserStatus()) {
    sendFacebookPixelInternalUserEvent();
  }

  if (window.fbEventQueue) {
    window.fbEventQueue.forEach((event) => {
      fbq(
        "trackSingle",
        getFacebookPixelId(),
        event.eventName,
        event.eventParams
      );
    });
  }
};

const trackGoogleAdsConversion = () => {
  if (
    typeof window.google_trackConversion === "function" &&
    typeof gacData !== "undefined" &&
    gacData.shouldIncludeTag &&
    gacData.conversionId > 0
  ) {
    const {
      memberType,
      ip,
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      company_type,
    } = Store.getState().user.analyticsData;
    const info = {
      ...gacData.tags,
      memberType,
      ip,
    };

    // eslint-disable-next-line camelcase
    if (company_type && company_type.length > 0) {
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      info.businessCategory = company_type;
    }

    const conversionData = {
      google_conversion_id: gacData.conversionId,
      google_conversion_label: gacData.conversionLabel,
      google_custom_params: info,
      google_remarketing_only: true,
    };

    if (info.hashedId > 0) {
      conversionData.google_user_id = info.hashedId;
    }

    window.google_trackConversion(conversionData);
  }
};

export const loadUserAnalytics = async () => {
  Store.subscribe(CheckForUserDataChangesAndUpdate);

  // @TODO Add this on the actual element instead of using a query selector
  const quickCheckoutButton = document.getElementsByClassName("gtm-quick-co");
  if (quickCheckoutButton.length === 1) {
    quickCheckoutButton[0].addEventListener("click", () => {
      let ccType = document.querySelector(
        "button.btn-select > span.credit-icon"
      );
      if (ccType === null) {
        ccType = "credit_card";
      } else {
        ccType = ccType.innerText;
      }
      sendCheckoutEventPageView("QuickCheckout", getCheckoutFunnelType());
      initiateLegacyFacebookCheckout();
      addPaymentInfo(ccType, true);
      session.setItem("quick_checkout", true);
      setViewCartShipping();
      getCartAndSendShippingEvent();
    });
  }

  const footerSignupForm = document.getElementById("footerEmailSignup");
  if (footerSignupForm !== null) {
    footerSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const emailSignupForm = document.getElementById("emailform");
  if (emailSignupForm !== null) {
    emailSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const outletEmailSignupForm = document.getElementById("outletEmailForm");
  if (outletEmailSignupForm !== null) {
    outletEmailSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const productRecommendationsEmailform = document.getElementById(
    "productRecommendationsEmailform"
  );
  if (productRecommendationsEmailform !== null) {
    productRecommendationsEmailform.addEventListener(
      "submit",
      emailSignupEvent
    );
  }

  trackGoogleAdsConversion();

  loadFacebookPixel();
  const user = getUserForPinterestEvent();
  await new PinterestTrackingPixel(user?.em).load();

  trackYmanCarouselViews();

  bindYmanClickEvent();

  trackRPFYCarouselViews();

  addPriceSpiderTokenToSessionStorage();

  await getDynamicData;
  CheckForUserDataChangesAndUpdate();
  sendGtmEvent({ event: "CustomerDataLoaded" });

  new PinterestApiEvent(
    "page_visit",
    new PinterestTagPageVisitEvent()
  ).sendEvent();
};

window.addEventListener("load", () => {
  bindTrackingForElements("[gtm-tracking-number]", getDataForProductsInRow, 80);
  bindTrackingForElements("[gtm-carousel-name]", trackCarouselImpression, 80);
});
