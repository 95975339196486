/* eslint-disable no-console */
import { OrderItemAnalyticsViewModel } from "~/FullCheckout/Shared/Analytics/CheckoutAnalyticsViewModel";
import QueryStringParameterSearch from "~/common/QueryString/QueryStringParameterSearch";

declare global {
  interface Window {
    pswtb: PriceSpider;
  }
}

export interface PriceSpider {
  purchaseTracking: PurchaseTracking;
}

interface PurchaseTracking {
  createOrder: () => PriceSpiderOrder;
}

export interface PriceSpiderOrder {
  redirectToken: string;
  purchaseDate: Date | string;
  currencyCode: string;
  purchaseTotal: number;
  purchaseDiscount: number;
  details: PriceSpiderItem[];
  submit: () => void;
}

interface PriceSpiderItem {
  id?: string;
  sku: string;
  manufacturer: string;
  productName: string;
  unitPrice: number;
  quantity: number;
}

export const PriceSpiderTokenStorageName = "ps_token";
export const PriceSpiderQueryParameterName = "psrid";

export const sendPriceSpiderPurchaseEvent = (
  shouldOnlyLogEvent: boolean,
  total: number,
  discount: number,
  datePurchased: string,
  items: OrderItemAnalyticsViewModel[],
  addScript: (src: string, async?: boolean, callback?: () => void) => void
) => {
  const redirectToken = sessionStorage.getItem(PriceSpiderTokenStorageName);
  if (!redirectToken) {
    return;
  }

  if (!items.some((item) => item.canSendToPriceSpider)) {
    sessionStorage.removeItem(PriceSpiderTokenStorageName);
    return;
  }

  const priceSpiderItems: PriceSpiderItem[] = items
    .filter((item) => item.canSendToPriceSpider)
    .map((item) => ({
      sku: item?.manufacturerSku ?? "",
      manufacturer: item.manufacturer,
      productName: item.description,
      unitPrice: item.price,
      quantity: item.quantity,
    }));

  if (shouldOnlyLogEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    console &&
      console.log({
        redirectToken,
        purchaseDate: datePurchased,
        currencyCode: "USD",
        purchaseTotal: total,
        purchaseDiscount: discount,
        details: priceSpiderItems,
      });
  } else {
    const sendEvent = () => {
      const order = window.pswtb.purchaseTracking.createOrder();

      order.redirectToken = redirectToken;
      order.purchaseDate = datePurchased;
      order.currencyCode = "USD";
      order.purchaseTotal = total;
      order.purchaseDiscount = discount;
      order.details = priceSpiderItems;

      order.submit();
    };

    addScript(
      "https://cdn.pricespider.com/pathinsights/ps-pi-ps.min.js",
      true,
      sendEvent
    );
  }

  sessionStorage.removeItem(PriceSpiderTokenStorageName);
};

export const addPriceSpiderTokenToSessionStorage = () => {
  const priceSpiderToken = QueryStringParameterSearch(
    PriceSpiderQueryParameterName
  );

  if (priceSpiderToken) {
    sessionStorage.setItem(PriceSpiderTokenStorageName, priceSpiderToken);
  }
};
