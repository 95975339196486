import React from "react";
import { Icon } from "@clarkinc/zest-design-system/ui";
import ChildOrNull from "~/common/ChildOrNull";

type IconSizes = "2xsmall" | "xsmall" | "small" | "medium" | "large" | "xlarge";

interface IconProps {
  height?: string;
  name?: string;
  size?: IconSizes | null;
  style?: React.CSSProperties;
  width?: string;
}

interface MenuItemProps {
  content?: string | JSX.Element[] | JSX.Element;
  divider?: boolean;
  extraListClasses?: string;
  href?: string;
  iconProps?: IconProps;
  name?: string;
  renderIcon?: boolean;
}

const MenuItem = ({
  content = "",
  divider = false,
  extraListClasses = "",
  href = "",
  iconProps,
  name,
  renderIcon,
}: MenuItemProps) => {
  const iconPositioning = iconProps ? "flex items-center" : "";
  return (
    <li className={`w-full ${extraListClasses}`}>
      <ChildOrNull condition={divider}>
        <div className="bg-gray-200 border-white border-solid border-b overflow-hidden h-px mt-2 mb-0.5" />
      </ChildOrNull>
      <div className={iconPositioning}>
        <a
          href={href}
          className="relative whitespace-nowrap flex items-center font-normal text-sm leading-snug py-1-1/2 px-2 text-gray-800 rounded hover:underline hover:bg-gray-100 active:bg-gray-100"
          data-testid={name}
        >
          {content}
        </a>
        {renderIcon && <Icon theme="wss" {...iconProps} />}
      </div>
    </li>
  );
};

export default MenuItem;
