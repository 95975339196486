/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-restricted-imports
import { Breadcrumb, Event as SentryEvent, EventHint } from "@sentry/react";

export function getRecentSentryBreadcrumbs(
  error: SentryEvent
): Breadcrumb[] | null {
  if (!error.breadcrumbs) return null;

  const now = Date.now();
  const breadcrumbs: Breadcrumb[] = [];

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (const breadcrumb of error.breadcrumbs) {
    // eslint-disable-next-line no-continue
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) break;

    breadcrumbs.push(breadcrumb);
  }

  return breadcrumbs.length ? breadcrumbs : null;
}

function isErrorAndNetworkBreadcrumb(breadcrumb: Breadcrumb): boolean {
  const isError = breadcrumb.level === "error";
  const isRequest =
    breadcrumb.category === "xhr" || breadcrumb.category === "fetch";
  return isError && isRequest;
}

function isChunkLoadError(breadcrumb: Breadcrumb): boolean {
  return (
    !!breadcrumb.message?.includes("ChunkLoadError") ||
    !!breadcrumb.message?.includes("Loading CSS chunk")
  );
}

/** The known list of AppLovin related variable names.  We'll filter reference errors for these vars. */
const applovinVarNames = [
  "al_onPoststitialDismiss",
  "al_onAppResumed",
  "al_onAppWillResignActive",
  "al_onAppPaused",
  "al_onAdViewRendered",
];

/** Filter error messages that come from some mobile app's implementation of AppLovin (exact source unknown - no references in our code)
 *  @see {@link https://tfs.clarkinc.biz/DefaultCollection/Webstaurant.StoreFront/_workitems/edit/1283103/ Related RCA } for current information and theories
 */
export function isAppLovinMobileError(hint: EventHint) {
  const { originalException } = hint;

  if (!(originalException instanceof ReferenceError)) {
    return false;
  }

  return applovinVarNames.some((alVarName) => {
    return originalException.message.includes(
      `Can't find variable: ${alVarName}`
    );
  });
}

/** Blocks raw messages that are google-translate related */
export function isGoogleTranslateMessage(event: SentryEvent): boolean {
  const googleTranslateUrlHint = ".translate.goog/";
  // The CORS errors that come up from google translate are often "raw" messages, so we need to look directly at the content
  if (event) {
    if (event.message?.includes(googleTranslateUrlHint)) {
      return true;
    }
    if (event.request?.url?.includes(googleTranslateUrlHint)) {
      return true;
    }
  }
  return false;
}

/** Blocks Sentry errors that originate from Ad Blockers that block the following urls */
const blockedUrls = [
  "s.pinimg.com",
  "ct.pinterest.com",
  "analytics.google.com",
];

export function isProbablyBlockedByAdblockNetworkError(
  breadcrumbs: Breadcrumb[]
): boolean {
  for (const breadcrumb of breadcrumbs) {
    // eslint-disable-next-line no-continue
    if (!isErrorAndNetworkBreadcrumb(breadcrumb)) continue;

    const url = breadcrumb.data?.url;
    if (!url) return false;

    const isBlocked = blockedUrls.some((blocked) => url.includes(blocked));
    if (isBlocked) return true;
  }

  return false;
}

/** Blocks sentry errors that come from rejects recieving empty objects */
export function isEmptyObjectReject(hint: EventHint) {
  const { originalException } = hint;

  if (originalException?.constructor !== Object) {
    return false;
  }

  return Object.keys(originalException).length === 0;
}

/**
 * Blocks sentry errors that come from the Google ReCaptcha component being unmounted
 *
 * @see {@link https://github.com/getsentry/sentry-javascript/issues/2514 Sentry Github issue containing recommended snippet}
 * @see {@link https://github.com/google/recaptcha/issues/269#issuecomment-1414409419 Discussion on the ReCaptcha PHP project}
 */
export function isGoogleRecaptchaTimeout(hint: EventHint) {
  if (!hint.originalException) return false;
  const timeoutRegex = /^Timeout \([a-z]\)$/;
  const error = hint.originalException.toString();
  return error === "Timeout" || timeoutRegex.test(error);
}

export function isProbablyCausedByHoneyExtension(
  breadcrumbs: Breadcrumb[]
): boolean {
  for (const breadcrumb of breadcrumbs) {
    const isUiClickEvent = breadcrumb.category === "ui.click";
    const isHoneyContainer = breadcrumb.message?.includes("#honeyContainer");
    if (isUiClickEvent && isHoneyContainer) return true;
  }
  return false;
}

/**
 * Blocks sentry errors that come from unhandled rejections attached to DOM onerror handlers.
 *
 * Initially only blocking events that lack details, we may want to be more aggressive if the
 * events with details continue to be of low quality.
 *
 * @see {@link https://github.com/getsentry/sentry-javascript/issues/2546 Sentry Github issue with discussion}
 * @see {@link https://tfs.clarkinc.biz/DefaultCollection/Webstaurant.StoreFront/_workitems/edit/1195716 Related PBI and internal discussion}
 */
export function isNonErrorDomEventRejection(hint: EventHint) {
  const { originalException } = hint;

  if (!(originalException instanceof Event)) {
    return false;
  }

  if (originalException instanceof CustomEvent) {
    return originalException.detail === null;
  }

  return true;
}

/** Blocks Sentry Errors that come from the page being closed, refreshed, or canceled while a resource is loading */
let shouldLogError = false;

window.addEventListener("load", function handleWindowLoadEvent() {
  shouldLogError = true;
});

/**
 * MDN recommends using visibility change over beforeUnload since mobile devices do not always fire the beforeUnload event
 *
 * @see {@link https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/ Article Explaining Why}
 */
document.addEventListener(
  "visibilitychange",
  function handleBeforeUnloadEvent() {
    shouldLogError = document.visibilityState === "visible";
  }
);

/** Not all browsers currently support visibilitychange, pagehide is the next best option */
window.addEventListener("pagehide", function handlePageHideEvent() {
  shouldLogError = false;
});

window.addEventListener("pageshow", function handlePageHideEvent() {
  shouldLogError = true;
});

/** Used in the browser to silence errors caused by reloading the page */
window.addEventListener("beforeunload", function handlePageHideEvent() {
  shouldLogError = false;
});

/** Used in the browsers that do not support beforeunload */
window.addEventListener("unload", function handlePageHideEvent() {
  shouldLogError = false;
});

/** Return true to skip logging the error */
export function isLoggingNetworkErrorDuringPageUnload(
  breadcrumbs: Breadcrumb[]
): boolean {
  if (shouldLogError) return false;
  for (const breadcrumb of breadcrumbs) {
    // eslint-disable-next-line no-continue
    if (isErrorAndNetworkBreadcrumb(breadcrumb)) return true;
    if (isChunkLoadError(breadcrumb)) return true;
  }

  return false;
}

export function isGoogleMissingGmoVariableMessage(event: SentryEvent): boolean {
  if (event) {
    if (event.message?.includes("Can't find variable: gmo")) {
      return true;
    }
  }
  return false;
}

/** Combine simple event filters (ones that can be evaluated just from the event / hint)
 *  to reduce complexity in sentry beforeSend functions.
 */
export function isSimpleIgnoredEvent(
  event?: SentryEvent,
  hint?: EventHint
): boolean {
  if (
    event &&
    (isGoogleTranslateMessage(event) ||
      isGoogleMissingGmoVariableMessage(event))
  ) {
    return true;
  }
  if (
    hint &&
    (isEmptyObjectReject(hint) ||
      isGoogleRecaptchaTimeout(hint) ||
      isAppLovinMobileError(hint) ||
      isNonErrorDomEventRejection(hint))
  ) {
    return true;
  }

  return false;
}
