import React, { HTMLProps, useRef } from "react";
import { useProductImpressionTracking } from "./useProductImpressionTracking";

/* eslint-disable import/prefer-default-export */

export const TrackedCarouselItem = (props: HTMLProps<HTMLLIElement>) => {
  const liRef = useRef<HTMLLIElement>(null);
  useProductImpressionTracking(liRef);
  const { children, ...rest } = props;
  return (
    <li {...rest} ref={liRef}>
      {children}
    </li>
  );
};
