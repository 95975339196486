import { logException } from "@wss/error-tracking";
import { useEffect, useState } from "react";
import { PlusSubscription } from "~/my-account/Plus/plusSubscription";
import { plusSubscriptionProvider } from "~/my-account/Plus/plusSubscriptionProvider";
import { IsEmptyGuid } from "../../../common/helpers/constants";
import { AlertType } from "./AlertTypes";

const normalizeDate = (date: Date) => {
  const normalizedDate = new Date(date);
  normalizedDate.setHours(0, 0, 0, 0); // Set time to midnight
  return normalizedDate;
};

const getAlertType = (
  multipleCardsExpiringThisMonth: boolean,
  soonestExpiringCard: PlusSubscription | null,
  soonestRenewalCard: PlusSubscription | null,
  multipleCardsExpiringThisWeek: boolean,
  currentlyExpiredCard: PlusSubscription | null,
  multipleCurrentlyExpiredCards: PlusSubscription[] | null
): AlertType | null => {
  const currentDate = new Date();
  const oneWeekFromNow = new Date();
  oneWeekFromNow.setDate(currentDate.getDate() + 7);

  // Check for multiple currently expired cards
  if (
    multipleCurrentlyExpiredCards?.length &&
    multipleCurrentlyExpiredCards.length > 1 &&
    multipleCurrentlyExpiredCards[0]?.renewalDate
  ) {
    const renewalDate = new Date(multipleCurrentlyExpiredCards[0].renewalDate);
    const dayAfterRenewal = new Date(renewalDate);
    dayAfterRenewal.setDate(renewalDate.getDate() + 1);

    // Normalize the dayAfterRenewal date
    const normalizedDayAfterRenewal = normalizeDate(dayAfterRenewal);

    // Calculate the end date, 14 days after the renewal date
    const endDate = new Date(renewalDate);
    endDate.setDate(renewalDate.getDate() + 14);

    // Normalize the endDate to midnight
    const normalizedEndDate = normalizeDate(endDate);

    // Normalize the currentDate to midnight
    const normalizedCurrentDate = normalizeDate(currentDate);

    if (
      normalizedCurrentDate >= normalizedDayAfterRenewal &&
      normalizedCurrentDate <= normalizedEndDate
    ) {
      return "MultipleCurrentlyExpired";
    }
  }

  // Check for a single currently expired card
  if (currentlyExpiredCard) {
    const renewalDate = new Date(currentlyExpiredCard.renewalDate);
    const dayAfterRenewal = new Date(renewalDate);
    dayAfterRenewal.setDate(renewalDate.getDate() + 1);

    const normalizedDayAfterRenewal = normalizeDate(dayAfterRenewal);

    const endDate = new Date(renewalDate);
    endDate.setDate(renewalDate.getDate() + 14);

    const normalizedEndDate = normalizeDate(endDate);

    const normalizedCurrentDate = normalizeDate(currentDate);

    if (
      normalizedCurrentDate >= normalizedDayAfterRenewal &&
      normalizedCurrentDate <= normalizedEndDate
    ) {
      return "CurrentlyExpired";
    }
  }

  if (soonestRenewalCard) {
    const cardExpirationDate = new Date(soonestRenewalCard.cardExpiration);
    const renewalDate = new Date(soonestRenewalCard.renewalDate);

    if (
      cardExpirationDate <= currentDate &&
      renewalDate >= currentDate &&
      renewalDate <= oneWeekFromNow
    ) {
      return multipleCardsExpiringThisWeek ? "WeekMulti" : "WeekSingle";
    }
  }

  if (!soonestExpiringCard) {
    return null;
  }

  if (!multipleCardsExpiringThisMonth) {
    return "MonthSingle";
  }

  if (multipleCardsExpiringThisMonth) {
    return "MonthMulti";
  }

  return null;
};

const useExpiringCard = ({ guid }: { guid?: string }) => {
  const [alertType, setAlertType] = useState<AlertType | null>(null);

  const findSoonestExpiringCard = (
    subscriptions: PlusSubscription[]
  ): PlusSubscription | null => {
    if (subscriptions.length === 0) {
      return null;
    }

    return subscriptions.reduce((soonest, current) => {
      const soonestExpirationDate = new Date(soonest.cardExpiration);
      const currentExpirationDate = new Date(current.cardExpiration);
      return currentExpirationDate < soonestExpirationDate ? current : soonest;
    });
  };

  const findSoonestRenewalCard = (
    subscriptions: PlusSubscription[]
  ): PlusSubscription | null => {
    if (subscriptions.length === 0) return null;

    const soonest = subscriptions.reduce((soonest, current) => {
      const soonestRenewalDate = new Date(soonest?.renewalDate ?? 0);
      const currentRenewalDate = new Date(current.renewalDate);
      return currentRenewalDate < soonestRenewalDate ? current : soonest;
    }, subscriptions[0] ?? null);

    return soonest;
  };

  const findCurrentlyExpiredCard = (
    subscriptions: PlusSubscription[]
  ): PlusSubscription | null => {
    if (subscriptions.length === 0) {
      return null;
    }

    const expiredCard = subscriptions.find((subscription) => {
      const expirationDate = new Date(subscription.cardExpiration);
      const isExpired = expirationDate <= new Date();

      return isExpired;
    });

    return expiredCard || null;
  };

  useEffect(() => {
    const getCards = async (guid: string) => {
      try {
        const provider = plusSubscriptionProvider();
        const { result } = await provider.getSubscriptions(guid);

        if (result) {
          const currentDate = new Date();
          const oneMonthFromNow = new Date(currentDate);
          oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
          const oneWeekFromNow = new Date(currentDate);
          oneWeekFromNow.setDate(currentDate.getDate() + 7);

          const expiringCardsNextMonth = result.subscriptions.filter(
            (subscription) => {
              const expiry = new Date(subscription.cardExpiration);
              return (
                expiry >= currentDate &&
                expiry <= oneMonthFromNow &&
                subscription.status === "Active"
              );
            }
          );

          const expiringCardsNextWeek = result.subscriptions.filter(
            (subscription) => {
              const renewalDate = new Date(subscription.renewalDate);
              const isExpiringNextWeek =
                renewalDate >= currentDate && renewalDate <= oneWeekFromNow;
              const isExpiredPaymentMethod =
                new Date(subscription.cardExpiration) <= currentDate;

              return (
                isExpiringNextWeek &&
                isExpiredPaymentMethod &&
                subscription.status === "Active"
              );
            }
          );

          const multipleCurrentlyExpiredCards = result.subscriptions.filter(
            (subscription) => {
              const expirationDate = new Date(subscription.cardExpiration);
              return expirationDate <= currentDate;
            }
          );

          const uniqueExpiringCardsNextMonth = new Set(
            expiringCardsNextMonth.map((card) => card.paymentMethodId)
          );

          const uniqueExpiringCardsNextWeek = new Set(
            expiringCardsNextWeek.map((card) => card.paymentMethodId)
          );

          const currentlyExpiredCard = findCurrentlyExpiredCard(
            result.subscriptions
          );

          const alertType = getAlertType(
            uniqueExpiringCardsNextMonth.size > 1,
            findSoonestExpiringCard(expiringCardsNextMonth),
            findSoonestRenewalCard(expiringCardsNextWeek),
            uniqueExpiringCardsNextWeek.size > 1,
            currentlyExpiredCard,
            multipleCurrentlyExpiredCards.length > 1
              ? multipleCurrentlyExpiredCards
              : null
          );

          setAlertType(alertType);
        }
      } catch (error) {
        logException(error);
      }
    };

    if (!guid || IsEmptyGuid(guid)) {
      return;
    }

    getCards(guid);
  }, [guid]);

  return { alertType };
};

export const urlToKey = (url: string) => {
  switch (true) {
    case url.includes("plus"):
      return "plus";
    case url.includes("payment"):
      return "billing";
    case url.includes("shipping"):
      return "shipping";
    case url.includes("orders"):
      return "orders";
    default:
      return "dashboard";
  }
};

export const useShowAlert = (key: string, userId: string | undefined) => {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    if (!userId) {
      return;
    }
    const lastAlertTimes = localStorage.getItem(key);
    if (!lastAlertTimes) {
      setShowAlert(true);
      return;
    }

    const lastAlertTimesJSON = JSON.parse(lastAlertTimes);
    const currentTime = new Date().getTime();
    const windowLocation = urlToKey(window.location.href);
    const oneDay = 24 * 60 * 60 * 1000;
    const lastAlertTime = lastAlertTimesJSON.find(
      (obj: { loc: string; time: string }) => obj.loc === windowLocation
    );
    if (!lastAlertTime) {
      setShowAlert(true);
      return;
    }

    if (lastAlertTime && currentTime - Number(lastAlertTime.time) > oneDay) {
      setShowAlert(true);
    }
  }, [key, userId]);
  return showAlert;
};

export default useExpiringCard;
