import React from "react";

interface DropDownProps {
  children: React.ReactNode;
  active: boolean;
  isCheckout?: boolean;
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const MenuDropdown = ({
  children,
  active,
  isCheckout = false,
  onKeyUp,
}: DropDownProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`bg-white rounded list-none ml-0 mt-1 absolute right-0 z-[60] shadow-xl min-w-[340px] bg-clip-padding ${
        active ? "block" : "hidden"
      }  min-w-[340px] ${!isCheckout && "lt:left-[-73px] xxl:left-0"}
      }`}
      onKeyUp={onKeyUp}
    >
      {children}
    </div>
  );
};

export default MenuDropdown;
