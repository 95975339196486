import { logException } from "@wss/error-tracking";
import { storageFactory } from "storage-factory";
import { sendGtmEvent } from "~/analytics/definitions";

export type CartFunction = (
  itemList: string,
  totalCost: string,
  discountCodes?: string
) => void;

type CartData = {
  cartContent: {
    item: string;
    price: number;
    quantity: number;
  }[];
  activeDiscountCodes: string;
};

const session = storageFactory(() => window.sessionStorage);

const getCartAndSendEvent = async (...callbacks: CartFunction[]) => {
  try {
    const response = await fetch("/api:cart/getcartwithfeedidentifier/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch cart data");
    }
    const cartData = await response.json<CartData>();
    const cartItems = cartData.cartContent;
    const discountCodes = cartData.activeDiscountCodes;
    let itemList = "";
    let totalCost = 0;

    cartItems.forEach((product, index) => {
      totalCost += product.price * product.quantity;
      itemList += product.item;
      if (index !== cartItems.length - 1) {
        itemList += ",";
      }
    });

    for (const callback of callbacks) {
      callback(itemList, Number(totalCost).toFixed(2), discountCodes);
    }
  } catch (error) {
    logException(error);
  }
};

export const getCartAndSendShippingEvent = () => {
  getCartAndSendEvent(addShippingInfo);
};

export const addShippingInfo: CartFunction = (
  itemList,
  totalCost,
  discountCodes
) => {
  const shipping = session.getItem("shipping");
  let quickCheckout = false;

  if (session.getItem("quick_checkout")) {
    quickCheckout = true;
  }

  sendGtmEvent({
    event: "add_shipping_info",
    ecommerce: {
      items: itemList,
      value: totalCost,
      coupon: discountCodes,
      shipping_tier: shipping,
      quick_checkout: quickCheckout,
    },
  });
};

export default getCartAndSendEvent;
