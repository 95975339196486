import React, { useMemo, useState } from "react";
import { Image } from "@wss/image";
import { Icon } from "@clarkinc/zest-design-system/ui";
import { NavItemData } from "../../DesktopNav/Item";
import { UserState } from "~/common/Store/models/RootReadonly";
import ChevronSvg from "./ChevronSvg";
import AccountIcon from "./AccountIcon";
import ExpandChevron from "./ExpandChevron";
import MenuButtons from "../MenuButtons";
import useExpiringCard from "~/my-account/Common/ExpiringCreditCards/useExpiringCard";

interface QuickLinkItem {
  label: string;
  href: string;
  hasIcon?: boolean;
  iconName?: string;
  iconColor?: string;
}
interface QuickLinkWithIconProps {
  item: QuickLinkItem;
  key: string;
}
const QuickLinkWithIcon: React.FC<QuickLinkWithIconProps> = ({ item, key }) => {
  return (
    <div className="flex items-center">
      <a
        href={item.href}
        className="relative flex justify-between items-center font-normal pb-4 text-gray-800 text-base"
        key={key}
        data-testid={`${item.label
          .toLowerCase()
          .replace(/\s/g, "-")}-quick-link`}
      >
        {item.label}
      </a>
      <Icon
        className="ml-1 pb-4"
        name={item.iconName}
        size="xsmall"
        style={{ fill: item.iconColor }}
        theme="wss"
      />
    </div>
  );
};

type Props = {
  navDataItems: NavItemData[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setClickedNavItem: React.Dispatch<
    React.SetStateAction<NavItemData | undefined>
  >;
  user: UserState;
};
const StepOne = ({
  navDataItems,
  setCurrentStep,
  setClickedNavItem,
  user,
}: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = (e: React.SyntheticEvent, navItem: NavItemData) => {
    e.preventDefault();
    setCurrentStep(2);
    setClickedNavItem(navItem);
  };

  const loggedInAccountOptions = useMemo(
    () =>
      user.registered
        ? [
            { label: "Account", href: "/myaccount/" },
            { label: "Orders", href: "/myaccount/orders/" },
            { label: "Auto Reorder", href: "/myaccount:autoreorder/" },
            { label: "Returns", href: "/myaccount/returns/" },
            {
              label: "Shipping",
              href: "/myaccount/shipping/?returnUrl= ",
            },
            { label: "Billing", href: "/myaccount/payment/" },
            {
              label: "Your Quotes",
              href: "/manage-quotes/",
              shouldDisplay: !!user.quotes,
            },
            {
              label: "Pending Orders",
              href: "/revieworder.html",
              shouldDisplay: !!user.pendingOrders,
              element: (
                <span className="flex justify-center items-center bg-green-500 p-1 w-3 h-3 rounded-full text-2xs font-bold text-white leading-none inline-block whitespace-nowrap ml-2">
                  {user.pendingOrders}
                </span>
              ),
            },
            { label: "Wish Lists", href: "/wishlist.html" },
            { label: "Rapid Reorder", href: "/reorder.html" },
            {
              label: "Order Guides",
              href: "/myproducts.html",
              shouldDisplay: !!user.orderGuideEnabled,
            },
            { label: "Log Out", href: "/myaccount/?logout=Y" },
          ]
            .filter(
              (option) =>
                option.shouldDisplay === undefined || option.shouldDisplay
            )
            .map((option) => ({
              ...option,
              key: `logged-in-account-option-${option.label.replace(
                /\s+/g,
                "-"
              )}`,
            }))
        : [],
    [user]
  );

  const { alertType: creditCardExpirationType } = useExpiringCard({
    guid: user.guid,
  });

  const getExpiringCardAlertColor = () => {
    switch (creditCardExpirationType) {
      case "MonthSingle":
      case "MonthMulti":
        return "#00AEEF"; // blue

      case "WeekSingle":
      case "WeekMulti":
        return "#B16818"; // orange

      case "MultipleCurrentlyExpired":
      case "CurrentlyExpired":
        return "#D0021B"; // red

      default:
        return undefined;
    }
  };
  const ccAlertColor = getExpiringCardAlertColor();

  const quickLinks = useMemo(() => {
    return user.registered
      ? [
          {
            label: "WebstaurantPlus",
            href: user.hasWebPlus ? "/myaccount/plus/" : "/plus/",
            hasIcon: !!creditCardExpirationType,
            iconName: "ri-error-warning-fill",
            iconColor: ccAlertColor,
          },
          { label: "Rewards", href: "/rewards/" },
          { label: "Get the App", href: "/services/app/" },
          {
            label: "VersaHub",
            href: "/versahub#/",
          },
        ]
      : [];
  }, [user, creditCardExpirationType, ccAlertColor]);

  const quickLinksNonRegisteredUser = useMemo(
    () =>
      !user.registered
        ? [
            {
              label: "WebstaurantPlus",
              href: "/plus/",
            },
            { label: "Rewards", href: "/rewards/" },
            { label: "Get the App", href: "/services/app/" },
            {
              label: "VersaHub",
              href: "/versahub#/",
            },
            {
              label: "Track Your Order",
              href: "/trackorder.html",
            },
          ]
        : [],
    [user]
  );

  const subMenuHeight = loggedInAccountOptions.length * 48 + 8;

  return (
    <ul className="m-0">
      <li className="flex list-none">
        <div className="w-full">
          <div className="flex items-start font-semibold list-none py-2 px-3 text-gray-800">
            <div className="flex flex-col py-1 px-2 w-full">
              {user.registered && (
                <div className="border border-solid border-gray-200 rounded mb-4">
                  <button
                    type="button"
                    className={`bg-transparent p-0 w-full h-12 border-l-0 border-r-0 border-t-0 border-solid border-gray-200 ${
                      expanded ? "border-b" : "border-b-0"
                    }`}
                    onClick={() => setExpanded((prev) => !prev)}
                    aria-expanded={expanded}
                  >
                    <span
                      className="flex
                        justify-between
                        items-center
                        h-full
                        px-4"
                    >
                      <span className="flex items-center mx-auto">
                        <AccountIcon />
                        <span
                          className="pl-2
                            text-base
                            leading-none
                            block
                            font-semibold
                            text-gray-800
                            capitalize"
                        >
                          {`${user.firstName}'s Account`}
                        </span>
                      </span>
                      <ExpandChevron isExpanded={expanded} />
                    </span>
                  </button>
                  <div
                    style={{
                      height: expanded ? `${subMenuHeight}px` : "0px",
                    }}
                    className="relative overflow-hidden transition-all mx-4"
                  >
                    <div className="absolute top-0 left-0 h-full w-full">
                      {expanded &&
                        loggedInAccountOptions.map((option) => (
                          <a
                            key={option.key}
                            href={option.href}
                            className="flex
                              items-center
                              font-normal
                              py-3
                              border-t-0
                              border-l-0
                              border-r-0
                              border-b
                              border-solid
                              border-gray-200
                              text-gray-800
                              text-base
                              last:pb-0
                              last:border-b-0"
                          >
                            {option.label}
                            {option.element}
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              )}
              <MenuButtons isLoggedIn={user.registered} />
              <div>
                <p
                  className="text-xl
                    block
                    font-bold
                    pb-3
                    text-gray-800
                    w-full
                    m-0"
                >
                  Departments
                </p>
                {navDataItems.map((navItem) => (
                  <a
                    href={navItem.link}
                    className="flex
                      items-center
                      justify-between
                      font-normal
                      pb-4
                      text-gray-800
                      text-base"
                    onClick={(e) => handleClick(e, navItem)}
                    key={navItem.displayName}
                  >
                    {navItem.displayName}
                    <ChevronSvg />
                  </a>
                ))}
              </div>
              {user.registered && (
                <div>
                  <a
                    className="text-xl
                      block
                      font-bold
                      pb-3
                      pt-2
                      text-gray-800
                      w-full"
                    href="##"
                  >
                    Quick Links
                  </a>
                  {quickLinks.map((item: QuickLinkItem) => {
                    if (item.hasIcon)
                      return <QuickLinkWithIcon key={item.label} item={item} />;
                    return (
                      <a
                        href={item.href}
                        className="relative flex justify-between items-center font-normal pb-4 text-gray-800 text-base"
                        key={item.label}
                        data-testid={`${item.label
                          .toLowerCase()
                          .replace(/\s/g, "-")}-quick-link`}
                      >
                        {item.label}
                      </a>
                    );
                  })}
                </div>
              )}
              {!user.registered && (
                <div>
                  <a
                    className="text-xl
                      block
                      font-bold
                      pb-3
                      pt-2
                      text-gray-800
                      w-full"
                    href="##"
                  >
                    Quick Links
                  </a>
                  {quickLinksNonRegisteredUser.map((item) => (
                    <a
                      href={item.href}
                      className="relative flex justify-between items-center font-normal pb-4 text-gray-800 text-base"
                      key={item.label}
                      data-testid={`${item.label
                        .toLowerCase()
                        .replace(/\s/g, "-")}-quick-link`}
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div
            data-testid="get-app-ad-mobile-menu"
            className="mx-[18px] relative mb-12 h-32 xsl:h-[132px] xs:h-32 md:h-[132px] overflow-hidden"
            style={{
              backgroundImage:
                "url('//cdnimg.webstaurantstore.com/uploads/design/2024/7/w-background.jpg')",
              borderRadius: "8px",
              backgroundPosition: "right center",
              backgroundSize: "cover",
            }}
          >
            <a
              aria-label="Get the app"
              href="/services/app/"
              className="absolute top-0 left-0 w-full h-full"
            />
            <div className="!flex h-full items-center">
              <div className="-ml-12 xxs:ml-0 xs:-ml-4 [@media(min-width:815px)]:ml-0 h-full">
                <Image
                  className="h-full w-auto"
                  src="//cdnimg.webstaurantstore.com/uploads/design/2024/7/itemcollage3.png"
                  alt="Cup/Fork/IPhone collage"
                  width={83}
                  height={71}
                />
              </div>
              <div className="mr-2 xxs:mr-4">
                <p className="text-xl text-white xsl:text-2xl xs:text-xl font-bold mb-0">
                  Get the App
                </p>
                <p className="text-xs text-white xsl:text-sm xs:text-xs font-normal mb-2 xs:mb-4">
                  Stock up from anywhere, anytime.
                </p>
                <div className="!flex z-5 relative h-8">
                  <a
                    href="https://apps.apple.com/us/app/webstaurantstore/id1581450224"
                    aria-label="download"
                    data-testid="get-app-ad-mobile-menu-apple"
                    className="mr-2"
                  >
                    <Image
                      src="//cdnimg.webstaurantstore.com/uploads/design/2024/7/appstore-button.png"
                      alt="Apple Store"
                      className="w-auto h-full"
                      height={23}
                      width={68}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.webstaurantstore.storefront"
                    aria-label="download"
                    data-testid="get-app-ad-mobile-menu-android"
                  >
                    <Image
                      src="//cdnimg.webstaurantstore.com/uploads/custom_emails/2024/8/playstore2x.png"
                      alt="Google Play Store"
                      className="h-full w-auto"
                      height={23}
                      width={68}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default StepOne;
