/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";
import { sendProductImpressionEvent } from "~/userAnalytics";

let observer: IntersectionObserver | undefined;
if (typeof IntersectionObserver !== "undefined") {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const itemNumber = entry.target.getAttribute("data-item-number");
          if (!itemNumber) return;
          sendProductImpressionEvent([{ itemNumber }]);
          observer?.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.8 }
  );
}

export const useProductImpressionTracking = (
  ref: React.RefObject<HTMLLIElement>
) => {
  useEffect(() => {
    const refItem = ref.current;
    if (refItem && observer) {
      observer.observe(refItem);
    }
    return () => {
      if (refItem && observer) {
        observer.unobserve(refItem);
      }
    };
  }, [ref]);
};
