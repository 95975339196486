import React from "react";

interface SkipToMainLinkProps {
  isMobileNavOpen?: boolean;
}

const SkipToMainLink: React.FC<SkipToMainLinkProps> = ({ isMobileNavOpen }) => {
  const handleSkipToMain = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // Prefer using the 'skip-to-main-target' class to ensure the Skip Link focuses on the intended main content.
    const targetElement =
      document.getElementsByClassName("skip-to-main-target")[0] ||
      document.getElementById("main") ||
      document.querySelector('[role="main"]') ||
      document.querySelector("h1");

    if (!targetElement) return;

    const yOffset = -35;
    const targetPosition =
      targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({
      top: targetPosition,
      behavior: "auto",
    });

    if (targetElement instanceof HTMLElement) {
      targetElement.setAttribute("tabindex", "-1");
      targetElement.classList.add("outline-none");
      targetElement.focus();

      const handleBlur = () => {
        targetElement.removeAttribute("tabindex");
        targetElement.classList.remove("outline-none");
        targetElement.removeEventListener("blur", handleBlur);
      };

      targetElement.addEventListener("blur", handleBlur);
    }
  };
  return (
    <a
      href="#main"
      onClick={handleSkipToMain}
      tabIndex={isMobileNavOpen ? -1 : undefined}
      aria-hidden={isMobileNavOpen ? true : undefined}
      className="inline-flex h-6 underline font-semibold text-base leading-6 text-gray-800 sr-only focus:not-sr-only focus:p-2 focus:gap-2"
    >
      Skip to main content
    </a>
  );
};
export default SkipToMainLink;
