import React from "react";
import { cls } from "cls";
import { connect } from "react-redux";
import { UserState, RootState } from "~/common/Store/models/RootReadonly";
import SiteWideEventDayBanner from "./SiteWideEventDayBanner";
import PlusBadge from "./Logos/PlusBadge";
import RewardsBadge from "./Logos/RewardsBadge";
import Chevron from "./icons/Chevron";
import { PageCreatorBanner } from "./PageCreatorBanner";
import tracking from "~/Gtm/Tracking/tracking";
import RewardsText from "./RewardsBannerText";

interface AlertBannerRightContainerProps {
  user: UserState;
  isCustomerServiceMessageAvailable: boolean;
  eventDayBannerVisible: boolean;
  mounted: boolean;
  isEventDayDataLoading: boolean;
  isPlusEssentials?: boolean;
  pageCreatorBanner: PageCreatorBanner | null;
}

const AlertBannerRightContainer = ({
  user,
  isCustomerServiceMessageAvailable,
  eventDayBannerVisible,
  isEventDayDataLoading,
  isPlusEssentials,
  pageCreatorBanner,
}: AlertBannerRightContainerProps) => {
  const { textColor } = pageCreatorBanner ?? { textColor: "" };

  const changeStylesDependingOnEvent = (
    plusEssentialColor: string,
    alertBannerDefaultColor: string
  ) => {
    if (textColor && textColor.length > 3 && !isPlusEssentials) {
      return textColor;
    }
    if (isPlusEssentials) {
      return plusEssentialColor;
    }
    return alertBannerDefaultColor;
  };

  let shortTextClassNames = "block sm:hidden";
  let longTextClassNames = "hidden sm:block";

  if (eventDayBannerVisible) {
    shortTextClassNames = "block";
    longTextClassNames = "hidden";
  }

  if (isCustomerServiceMessageAvailable) {
    shortTextClassNames = "sm:hidden lt:block lz:hidden";
    longTextClassNames = "hidden sm:block lt:hidden lz:block";
  }

  if (eventDayBannerVisible && isCustomerServiceMessageAvailable) {
    shortTextClassNames = "sm:hidden lt:block";
    longTextClassNames = "hidden sm:block lt:hidden";
  }

  return (
    <div
      data-testid="alert-banner-right-container"
      className={cls(
        "space-x-6",
        "items-center",
        "justify-center",
        "lt:justify-between",
        "flex"
      )}
    >
      {isCustomerServiceMessageAvailable &&
        !isPlusEssentials &&
        pageCreatorBanner && (
          <SiteWideEventDayBanner
            pageCreatorBanner={pageCreatorBanner}
            classNames={cls("hidden", "xl:flex")}
          />
        )}
      {!isEventDayDataLoading ? (
        <div
          className={cls(
            !eventDayBannerVisible && // classes to be applied when there are no special properties
              !isPlusEssentials &&
              !isEventDayDataLoading
              ? "flex"
              : "",
            (eventDayBannerVisible && !isEventDayDataLoading) ||
              (isPlusEssentials && !isEventDayDataLoading) // classes to be applied when there is a special property
              ? "hidden lt:flex"
              : "",
            "space-x-6"
          )}
        >
          {!user.hasWebPlus && !isPlusEssentials && (
            <tracking.a
              data-track="web-plus-generic-global-header-ad"
              aria-label="Plus"
              href="/plus/"
              className={cls(
                "inline-flex",
                "items-center",
                "font-semibold",
                "text-xs",
                "text-gray-800",
                "hover:underline"
              )}
              style={textColor ? { color: textColor } : {}}
              data-testid="plus-banner"
            >
              <PlusBadge classNames={cls("pr-2")} />
              <span
                className={shortTextClassNames}
                data-testid="plus-short-text"
              >
                Unlock FREE Shipping!
              </span>
              <span className={longTextClassNames} data-testid="plus-long'text">
                Unlock Savings with FREE Shipping!
              </span>
              <Chevron
                strokeColor={changeStylesDependingOnEvent("#FFFFFF", "#3B4238")} // Function required to validate textColor is valid hex code
                classNames={cls("pl-2")}
              />
            </tracking.a>
          )}
          <a
            aria-label="Rewards"
            href={
              user.isRewardsMember ? "/myaccount/payment/#credits" : "/rewards/"
            }
            className={cls(
              "inline-flex",
              "items-center",
              "font-semibold",
              "text-xs",
              isPlusEssentials ? "text-white" : "text-gray-800",
              "hover:underline"
            )}
            style={textColor && !isPlusEssentials ? { color: textColor } : {}}
            data-testid="rewards-ad"
          >
            <RewardsBadge
              classNames={cls(
                isPlusEssentials || textColor ? "" : "mix-blend-multiply",
                "pr-2"
              )}
            />
            <RewardsText
              user={user}
              rewardsTextClassNames={
                eventDayBannerVisible ? "hidden lt:block" : "block"
              }
              shortTextClassNames={shortTextClassNames}
              longTextClassNames={longTextClassNames}
            />
            <Chevron
              strokeColor={changeStylesDependingOnEvent("#FFFFFF", "#3b4238")}
              classNames={cls("pl-2")}
            />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export const mapStateToProps = (
  state: RootState,
  ownProps: AlertBannerRightContainerProps
) => {
  if (!ownProps.mounted) {
    return {};
  }

  return state;
};

export default connect(mapStateToProps)(AlertBannerRightContainer);
